import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.exec.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import bigDecimal from "js-big-decimal";
import dayjs from "dayjs";
import { GET_TRANSACTIONS_USER } from "@/graphql/admin/transaction";
import ModalTransaction from "./ModalTransaction.vue";
export default {
  components: {
    ModalTransaction: ModalTransaction
  },
  props: ["data"],
  data: function data() {
    return {
      page: 1,
      pageSize: 10
    };
  },
  methods: {
    onComplete: function onComplete() {
      this.$apollo.queries.transactionsUser.refetch();
    },
    format_datetime: function format_datetime(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }

      return "-";
    },
    format_number: function format_number(val) {
      var decimal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (format) {
        if (val >= 0) return "\u0E3F".concat(Number(bigDecimal.round(val, decimal)).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        }));
        return "-\u0E3F".concat(Number(bigDecimal.round(Math.abs(val), decimal)).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal
        }));
      }

      return bigDecimal.round(val, decimal);
    }
  },
  apollo: {
    transactionsUser: {
      query: GET_TRANSACTIONS_USER,
      skip: function skip() {
        return !this.data || !this.data.userId;
      },
      variables: function variables() {
        return {
          pageSize: this.pageSize,
          page: this.page,
          search: this.search,
          userId: this.data.userId
        };
      }
    }
  }
};