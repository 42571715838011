<template>
  <b-modal id="transaction-modal" centered :title="title" @ok="handleOk">
    <b-overlay :show="$apollo.loading" rounded="sm">
      <b-form ref="form" class="form" @submit.stop.prevent="handleSubmit">
        <div class="form-group row">
          <label class="col-form-label text-right col-3"> </label>
          <div class="col-lg-8 col-9 d-flex align-items-center">
            <div class="radio-inline">
              <label class="radio">
                <input
                  type="radio"
                  name="type"
                  value="deposit"
                  v-model="form.type"
                />
                <span></span>
                เพิ่มเงิน
              </label>
              <label class="radio">
                <input
                  type="radio"
                  name="type"
                  value="withdraw"
                  v-model="form.type"
                />
                <span></span>
                ลดเงิน
              </label>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="input-amount" class="col-form-label text-right col-3">
            จำนวนเงิน(฿)
          </label>
          <div class="col-lg-8 col-9">
            <b-form-input
              id="input-amount"
              type="number"
              step="0.01"
              min="0"
              v-model="form.amount"
              required
              :state="validateState('amount')"
              placeholder=""
            ></b-form-input>
          </div>
        </div>
        <div class="form-group row" v-if="transactionTypeOptionList">
          <label
            for="input-transportNumber"
            class="col-form-label text-lg-right col-md-3 col-sm-12"
          >
            ประเภท
          </label>
          <div class="col-lg-6 col-md-9 col-sm-12">
            <v-select
              searchable
              labelTitle="กรุณาประเภท"
              data-style="btn-primary"
              required
              :state="validateState('transaction_type_id')"
              :options="transactionTypeOptionList.records"
              valueProp="value"
              v-model="form.transaction_type_id"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="input-reason" class="col-form-label text-right col-3">
            หมายเหตุ
          </label>
          <div class="col-lg-8 col-9">
            <b-form-input
              id="input-reason"
              v-model="form.reason_transaction"
              :state="validateState('reason_transaction')"
              placeholder=""
            ></b-form-input>
          </div>
        </div>
      </b-form>
    </b-overlay>
  </b-modal>
</template>
<script>
import VSelect from "@alfsnd/vue-bootstrap-select";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { GET_TRANSACTION_TYPE_LIST } from "@/graphql/admin/transaction";
import { CREATE_TRANSACTION } from "@/graphql/admin/transaction";

export default {
  props: ["title", "userId", "refetch", "is_refund"],
  mixins: [validationMixin],
  components: {
    VSelect
  },
  data() {
    return {
      nameState: null,
      form: {
        amount: 0,
        transaction_type_id: 0,
        reason_transaction: "",
        type: "deposit"
      }
    };
  },
  validations: {
    form: {
      amount: {
        required
      },
      type: { required },
      reason_transaction: {},
      transaction_type_id: { required }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const amount = this.$v.form.amount.$model;
      const type = this.$v.form.type.$model;
      const reason_transaction = this.$v.form.reason_transaction.$model;
      const transaction_type_id = Number(
        this.$v.form.transaction_type_id.$model.value
      );
      const input = {
        amount: type === "deposit" ? Number(amount) : -Number(amount),
        userId: this.userId,
        reason: reason_transaction,
        transaction_type_id
      };
      const {
        data: { createTransaction }
      } = await this.$apollo.mutate({
        mutation: CREATE_TRANSACTION,
        variables: { input }
      });
      if (!createTransaction.isSuccess) {
        this.$message.error(createTransaction.message);
      } else {
        this.$message.success(createTransaction.message);
        if (this.refetch) {
          this.refetch();
        }
        this.$nextTick(() => {
          this.$bvModal.hide("transaction-modal");
        });
      }
    }
  },
  apollo: {
    transactionTypeOptionList: {
      query: GET_TRANSACTION_TYPE_LIST
    }
  }
};
</script>
